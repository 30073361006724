



































































































































import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import ProductCardBadges from '~/components/molecules/Product/ProductCardBadges.vue';
import ProductCardPricing from '~/components/molecules/Product/ProductCardPricing/ProductCardPricing.vue';
import ProductCardWishlist from '~/components/atoms/Product/ProductCardWishlist.vue';
import ProductCardBrand from '~/components/atoms/Product/ProductCardBrand.vue';
import ProductCardDelivery from '~/components/atoms/Product/ProductCardDelivery.vue';
import ProductCardOverlay from '~/components/atoms/Product/ProductCardOverlay.vue';
import AddToCartButton from '~/components/atoms/Product/AddToCartButton.vue';
import { ProductTag } from '~/types/product/attribute/ProductTag';
import LowestPriceGuaranteed from '~/components/atoms/Product/LowestPriceGuaranteed.vue';
import LimitedTimeOffer from '~/components/atoms/Product/LimitedTimeOffer.vue';
import StockInformation from '~/components/molecules/Product/StockInformation.vue';
import buildProductRating from '~/helpers/product/buildProductRating';
import {
  useProductAvailabilityDate,
  useIntegrations,
} from '~/composables';
import ProductDimensionsInterface from '~/types/product/attribute/ProductDimensions';
import ProductCardGallery from '~/components/molecules/Product/ProductCardGallery/ProductCardGallery.vue';
import { isTestProduct } from '~/helpers/product/isTestProduct';
import { EnergyRatingDetails } from '~/types/components/EnergyRating/EnergyRatingDetails';
import type { ProductCategories } from '~/types/product/ProductCategories';
import Link from '~/components/atoms/Link/Link.vue';
import { LinkType } from '~/types/components/Link/LinkType';
import { getFinalPrice } from '~/helpers/product/getFinalPrice';

export default defineComponent({
  name: 'ProductCard',
  components: {
    ProductCardBadges,
    ProductCardPricing,
    ProductAverageReview: () => import(/* webpackChunkName: "ProductAverageReview" */
      '~/components/atoms/Product/ProductAverageReview.vue'),
    ProductCardWishlist,
    ProductCardDelivery,
    AddToCartButton,
    ProductCardBrand,
    LowestPriceGuaranteed,
    StockInformation,
    LimitedTimeOffer,
    ProductCardGallery,
    ProductCardOverlay,
    EnergyRating: () => import(/* webpackChunkName: "EnergyRating" */
      '~/components/molecules/Product/EnergyRating.vue'),
    Link,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    discountedPrice: {
      type: Number,
      default: null,
    },
    sku: {
      type: String,
      required: true,
    },
    reviewsCount: {
      type: Number,
      default: 0,
    },
    rating: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: '',
    },
    tags: {
      type: Array as PropType<ProductTag[]>,
      default: () => {
        return [];
      },
    },
    brand: {
      type: String,
      default: '',
    },
    dimensions: {
      type: Object as PropType<ProductDimensionsInterface>,
      default: () => {
        return { hasDimensions: false };
      },
    },
    priceGuaranteeUrl: {
      type: String,
      required: true,
    },
    stockCount: {
      type: Number,
      required: true,
    },
    parcelDeliveryDate: {
      type: String,
      default: '',
    },
    freightDeliveryDate: {
      type: String,
      default: '',
    },
    isFreight: {
      type: Boolean,
      default: false,
    },
    keyFacts: {
      type: Array as PropType<String[]>,
      default: () => [],
    },
    releaseDate: {
      type: String,
      required: true,
    },
    energyRating: {
      type: Object as PropType<Maybe<EnergyRatingDetails>>,
      default: (): Maybe<EnergyRatingDetails> => null,
    },
    currency: {
      type: String,
      required: true,
    },
    categories: {
      type: [Object, String] as PropType<ProductCategories>,
      required: true,
    },
    productBoxTypeTag: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const ratingToShow = computed(() => {
      return buildProductRating({
        averageRating: props.rating,
        count: props.reviewsCount,
      });
    });

    const isInStock = computed(() => props.stockCount > 0);
    const finalPrice = computed(() => getFinalPrice(props.discountedPrice, props.price));
    const showCardBadge = computed(() => props.tags.length);
    const deliveryDate = computed(() => props.isFreight ? props.freightDeliveryDate : props.parcelDeliveryDate);
    const showDeliveryTiming = computed(() => deliveryDate.value && isInStock.value);

    const { i18n } = useVSFContext();

    const dimensionsValue = computed(() => i18n.t('Dimensions Value', {
      length: props.dimensions.length,
      width: props.dimensions.width,
      height: props.dimensions.height,
    }));

    const handleTitleHover = () => {
      emit('title-hovered', props.sku);
    };

    const handleMouseLeave = () => {
      emit('title-leave');
    };

    const isTestProductItem = computed(() => isTestProduct(props.sku));
    const testProductIdentifier = computed(() => isTestProductItem.value ? 'test-product' : undefined);

    const { calculateAvailabilityDate } = useProductAvailabilityDate(props.releaseDate);
    const availabilityDate = computed(() => isTestProductItem.value ? calculateAvailabilityDate() : '');

    const { $tagManager } = useIntegrations();

    const onClick = () => {
      emit('product-clicked');
      $tagManager.events.triggerClickProductBoxTags({
        boxType: props.productBoxTypeTag,
        product: {
          id: props.sku,
          name: props.title,
          currency: props.currency,
          discountedPrice: finalPrice.value,
          brand: props.brand,
          price: props.price,
          categories: props.categories,
        },
      });
    };

    return {
      ratingToShow,
      finalPrice,
      showCardBadge,
      deliveryDate,
      showDeliveryTiming,
      onClick,
      dimensionsValue,
      isInStock,
      handleTitleHover,
      handleMouseLeave,
      testProductIdentifier,
      isTestProductItem,
      availabilityDate,
      LinkType,
    };
  },
});

